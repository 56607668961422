.page {
  display: flex;
  grid-column: 2/4;
  grid-row: 2/3;
  overflow-y: hidden;
  overflow-x: hidden;
  flex-direction: row;
}

.nav {
  display: flex;
  grid-column: 1/2;
  overflow-y: auto;
  min-width: 256px;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
}

.navItem {
  border-radius: 10px;
}

.content {
  padding-top: 32px;
  padding-bottom: 32px;
  flex: 1 1 auto;
  display: flex;
  grid-column: 2/3;
  overflow-y: auto;
  flex-direction: column;
  align-content: center;
}

.page blockquote {
  border-left: solid #77b9c5 4px;
  padding: 8px 16px;
  background-color: rgba(79, 117, 125, 0.2);
}
